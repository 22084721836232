import React from 'react'
import tw, { styled } from 'twin.macro'
import { useStateContext } from '../context/stateContext'
import bl01 from './../assets/spieleland/grafiken_memory_250x250_M4_01.png'
import homeBlocks from './../assets/spieleland/grafik_memory_hero_523x470_01.png'
import content from './../data/content.json'
import parse from 'html-react-parser'
import { motion } from 'framer-motion'
import useWindowSize from '../hooks/useWindowSize'
import Sound from '../components/Sound'

const Container = styled(motion.div)`
	${tw`fixed top-0 bottom-0 w-full h-full overflow-hidden bg-white md:bg-[#bcd88b] `}
`
const Wrap = styled.div`
	${tw`flex flex-col-reverse justify-between h-full md:flex-row`}
`
const Headline = styled.h1`
	${tw`mb-8 text-2xl font-extrabold text-center text-black xs:text-3xl mobile:text-4xl md:text-5xl`}
`
const Description = styled.p`
	${tw`mb-8 text-xl text-center text-green`}
`
const Score = styled.div`
	${tw`flex justify-center w-full mx-auto my-0 xs:my-6 md:my-10`}

	& .result {
		${tw`flex items-center justify-center w-10 h-10 mx-auto text-lg font-semibold text-center border border-blue xs:w-14 xs:h-14 md:w-20 md:h-20 lg:w-24 lg:h-24 rounded-xl text-blue md:text-2xl`}

		&-wrap {
			${tw`flex flex-col items-center mx-3`}

			.label {
				${tw`mt-3 text-xs font-semibold uppercase text-darkGray`}
			}
		}
	}
`
const ButtonWrap = styled.div`
	${tw`grid grid-cols-1 gap-3 md:grid-cols-2`}
`

const Main = styled(motion.div)`
	${tw`relative flex flex-col justify-center w-full py-10 bg-white h-3/5 md:h-auto md:py-0`}

	& .content {
		${tw`z-10 w-full px-5 mx-auto text-center md:px-0 md:w-4/5 xl:w-3/5 md:text-left`}

		h1, p {
			${tw`text-content`}

			@media screen and (max-width: 350px) {
				${tw`mb-4`}
			}
		}

		p {
			${tw`text-lg leading-normal md:text-xl text-green`}
		}
	}

	& .legals {
		${tw`absolute z-10 hidden w-3/5 text-xs transform -translate-x-1/2 md:block left-1/2 bottom-10 text-darkGray`}

		a:first-of-type {
			${tw`pr-6 border-r border-black`}
		}

		a:not(:first-of-type) {
			${tw`px-6`}
		}
	}
	& .main_01 {
		${tw`absolute top-0 left-0 z-0 hidden md:block`}
	}
	& .main_02 {
		${tw`absolute bottom-0 right-0 z-0 hidden md:block`}
	}
`

const LeftBar = styled(motion.div)`
	${tw`flex-wrap hidden w-full h-full lg:flex max-w-[100px] xl:max-w-[180px] 2xl:max-w-[244px] bg-[#bcd88b]`}

	@supports ( -moz-appearance:none ) {
		@media screen and (max-width: 1279px) {
			width: 25vh;
		}
		width: 55vh;
	}
`
const RightBar = styled(motion.div)`
	${tw`flex justify-center h-1/2 md:flex-col md:h-full bg-[#bcd88b]`}
	min-width: 40%;

	& .blocks {
		background-color: #bcd88b;
		height: calc(100% - 3rem);
		${tw`flex items-center justify-center flex-shrink w-full mt-auto md:h-full md:mt-0`}

		img {
			${tw`max-h-full`}
		}
	}
	& .horizontal-blocks {
		height: calc(100% - 3rem);
		@supports (-moz-appearance: none) {
			width: 15vh;
		}
		${tw`grid grid-rows-3 mt-auto ml-auto mr-auto md:w-full md:h-auto md:mr-auto md:ml-0 md:grid-rows-1 md:grid-cols-2 2xl:grid-cols-3 2k:grid-cols-5`}

		img {
			${tw`w-auto h-full max-w-none md:max-w-full md:h-auto`}
		}
	}
`
const RightBarMobileSpacer = styled(motion.div)`
	${tw`fixed w-full h-12 bg-white md:hidden`}
`

const EndScreen = () => {
	const stateContext = useStateContext()
	const { locale } = stateContext.state
	const t = content.langs
	const { isMobile } = useWindowSize()
	const rightSquareRef = React.useRef()

	const onRestartGame = async () => {
		stateContext.dispatch({ action: 'SET_GAME_STATUS', data: 'gameStart' })
		await new Promise((resolve) =>
			setTimeout(() => {
				return resolve()
			}, 600)
		)
		stateContext.dispatch({ action: 'RESET_GAME' })
	}

	const rightSquaresSize = () => {
		const rightSquaresContainer = rightSquareRef.current
		if (isMobile) {
			const imgSize = rightSquaresContainer.querySelector('img').getBoundingClientRect().height
			rightSquaresContainer.style.width = imgSize + 'px'
		} else {
			rightSquaresContainer.style.width = '100%'
		}
	}

	React.useEffect(() => {
		rightSquaresSize()
	}, [isMobile]) // eslint-disable-line react-hooks/exhaustive-deps

	const leftBarVariants = {
		animate: {
			transition: {
				type: 'tween',
				duration: 3,
				staggerChildren: 0.1,
			},
		},
		exit: {
			transition: {
				type: 'tween',
				duration: 3,
				staggerChildren: 0.1,
			},
		},
	}
	const mainVariants = {
		init: {
			y: isMobile ? '150%' : '-150%',
		},
		animate: {
			y: '0%',
			transition: {
				duration: 0.7,
			},
		},
		exit: {
			y: isMobile ? '150%' : '-150%',
			transition: {
				duration: 0.7,
			},
		},
	}
	const rightBarMainVariants = {
		init: {
			x: '150%',
		},
		animate: {
			x: '0%',
			transition: {
				duration: 0.7,
			},
		},
		exit: {
			x: '150%',
			transition: {
				duration: 0.7,
			},
		},
	}
	const rightBarBottomVariants = {
		animate: {
			transition: {},
		},
		exit: {
			transition: {
				staggerChildren: 0.15,
				duration: 0.7,
			},
		},
	}
	const rightBarBottomItemsVariants = {
		init: {
			y: '150%',
		},
		animate: {
			y: '0%',
			transition: {
				duration: 0.7,
			},
		},
		exit: {
			y: '150%',
			transition: {
				duration: 0.7,
			},
		},
	}
	const spacerVariants = {
		init: {
			y: '-150%',
		},
		animate: {
			y: '0%',
			transition: {
				duration: 0.7,
			},
		},
		exit: {
			y: '-150%',
			transition: {
				duration: 0.4,
			},
		},
	}

	return (
		<Container>
			<Sound mode='home' mobile={true} />
			<Wrap>
				<LeftBar className='' variants={leftBarVariants} initial='init' animate='animate' exit='exit'></LeftBar>
				<Main variants={mainVariants} initial='init' animate='animate' exit='exit'>
					<Sound mode='home' />
					<div className='content'>
						<Headline>{parse(t[locale].endHeadline)}</Headline>
						<Score>
							<div className='result-wrap'>
								<div className='result'>{stateContext.state.finalScore.pairs}</div>
								<p className='label'>{stateContext.state.finalScore.pairs === 1 ? t[locale].pairs[0] : t[locale].pairs[1]}</p>
							</div>
							<div className='result-wrap'>
								<div className='result'>{stateContext.state.finalScore.rounds}</div>
								<p className='label'>{stateContext.state.finalScore.rounds === 1 ? t[locale].rounds[0] : t[locale].rounds[1]}</p>
							</div>
						</Score>
						<Description>{parse(t[locale].endDescription)}</Description>
						<ButtonWrap>
							<a
								href='https://www.ravensburger.de/family-friends/gemeinsamkeit/spieleland-memory-gewinnspiel/spieleland-memory-gewinnspiel-teilnahmeformular/index.html'
								target='_blank'
								rel='noopener noreferrer'
								className='border border-transparent bg-blue text-white py-3 md:py-4 px-2 text-center text-xs rounded-xl uppercase tracking-wider mx-auto md:mx-0 w-[180px] md:w-auto'
							>
								{t[locale].ButtonExternal}
							</a>
							<button className='border border-blue text-blue py-3 md:py-4 px-2 text-center text-xs rounded-xl uppercase tracking-wider mx-auto md:mx-0 w-[180px] md:w-auto' onClick={() => onRestartGame()}>
								{t[locale].endRestartButton}
							</button>
						</ButtonWrap>
					</div>
				</Main>
				<RightBar>
					<RightBarMobileSpacer variants={spacerVariants} initial='init' animate='animate' exit='exit' />
					<motion.div className='blocks' variants={rightBarMainVariants} initial='init' animate='animate' exit='exit'>
						<img src={homeBlocks} alt='' />
					</motion.div>
					<motion.div ref={rightSquareRef} variants={rightBarBottomVariants} initial='init' animate='animate' exit='exit' className='horizontal-blocks'>
						<motion.img variants={rightBarBottomItemsVariants} src={bl01} alt='' />
						<motion.img variants={rightBarBottomItemsVariants} src={bl01} alt='' />
						<motion.img className='md:hidden 2xl:block' variants={rightBarBottomItemsVariants} src={bl01} alt='' />
						<motion.img className='hidden 2k:block' variants={rightBarBottomItemsVariants} src={bl01} alt='' />
						<motion.img className='hidden 2k:block' variants={rightBarBottomItemsVariants} src={bl01} alt='' />
					</motion.div>
				</RightBar>
			</Wrap>
		</Container>
	)
}

export default EndScreen
