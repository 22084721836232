import React, { useEffect, useCallback, useRef } from 'react'
import tw, { styled } from 'twin.macro'
import { useStateContext } from '../context/stateContext'
import { motion } from 'framer-motion'
import bl01 from './../assets/spieleland/grafiken_memory_250x250_M4_01.png'
import homeBlocks from './../assets/spieleland/grafik_memory_hero_523x470_01.png'
import useWindowSize from './../hooks/useWindowSize'
import content from './../data/content.json'
import parse from 'html-react-parser'
import Sound from './Sound'
import { Howl } from 'howler'
import transitionSound from '../assets/sounds/sound_transition.wav'
import logoMemory from './../assets/logo-memory.png'

const Container = styled(motion.div)`
	${tw`fixed top-0 bottom-0 w-full h-full overflow-hidden bg-[#bcd88b]`}
`
const Wrap = styled.div`
	${tw`flex flex-col-reverse justify-between h-full md:flex-row`}
`
const Overline = styled.p`
	${tw`mb-4 text-xl font-extrabold text-black md:text-2xl`}
`
const Description = styled.p`
	${tw`mb-4 text-xs text-black md:text-base xs:mb-8`}
`

const Main = styled(motion.div)`
	${tw`relative flex flex-col justify-center w-full py-10 bg-white h-1/2 md:h-auto md:py-0`}

	& .content {
		${tw`relative z-10 w-full px-5 mx-auto text-center md:px-0 md:pl-10 xl:pl-20 xs:w-4/5 sm:w-3/5 md:text-left`}

		h1, p {
			${tw`text-content`}
		}
	}

	& .legals {
		${tw`absolute w-full text-center transform -translate-x-1/2 md:text-left md:w-3/5 left-1/2 bottom-3 md:bottom-10 text-9px xs:text-xs text-darkGray`}

		a:first-of-type {
			${tw`px-4 border-r border-black md:px-0 md:pr-4`}
		}

		a:not(:first-of-type) {
			${tw`px-4 `}
		}
	}
	& .main_01 {
		${tw`absolute top-0 left-0 hidden md:block`}
	}
	& .main_02 {
		${tw`absolute bottom-0 right-0 hidden md:block`}
	}
`

const LeftBar = styled(motion.div)`
	${tw`flex-wrap hidden w-full h-full lg:flex max-w-[100px] xl:max-w-[180px] 2xl:max-w-[244px] bg-[#bcd88b]`}
`
const RightBar = styled(motion.div)`
	${tw`flex justify-center h-1/2 md:flex-col md:h-full bg-[#bcd88b]`}
	min-width: 40%;

	& .blocks {
		background-color: #bcd88b;
		height: calc(100% - 3rem);
		${tw`flex items-center justify-center flex-shrink w-full mt-auto md:h-full md:mt-0`}

		img {
			${tw`max-h-full`}
		}
	}
	& .horizontal-blocks {
		height: calc(100% - 3rem);
		@supports (-moz-appearance: none) {
			width: 15vh;
		}
		${tw`grid grid-rows-3 mt-auto ml-auto mr-auto md:w-full md:h-auto md:mr-auto md:ml-0 md:grid-rows-1 md:grid-cols-2 2xl:grid-cols-3 2k:grid-cols-5`}

		img {
			${tw`w-auto h-full max-w-none md:max-w-full md:h-auto`}
		}
	}
`
const RightBarMobileSpacer = styled(motion.div)`
	${tw`fixed w-full h-12 bg-white md:hidden`}
`

const Welcome = () => {
	const stateContext = useStateContext()
	const { playSound } = useStateContext()
	const { locale } = stateContext.state
	const t = content.langs
	const { isMobile } = useWindowSize()
	const rightSquareRef = useRef()

	const onStartGameClick = useCallback(() => {
		stateContext.dispatch({ action: 'SET_GAME_STATUS', data: 'gameStart' })
		if (stateContext.state.sound) {
			playSound('transition')
		}
	}, [stateContext]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (stateContext.state.sounds.transition === null) {
			const newSound = new Howl({
				src: [transitionSound],
				volume: 0.5,
			})

			stateContext.dispatch({ action: 'SET_SOUND_FILE', data: 'transition', src: newSound })
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const rightSquaresSize = () => {
		const rightSquaresContainer = rightSquareRef.current
		if (isMobile) {
			const imgSize = rightSquaresContainer.querySelector('img').getBoundingClientRect().height
			rightSquaresContainer.style.width = imgSize + 'px'
		} else {
			rightSquaresContainer.style.width = '100%'
		}
	}

	useEffect(() => {
		rightSquaresSize()
	}, [isMobile]) // eslint-disable-line react-hooks/exhaustive-deps

	const leftBarVariants = {
		animate: {
			transition: {
				type: 'tween',
				duration: 0.7,
				staggerChildren: 0.1,
			},
		},
		exit: {
			transition: {
				type: 'tween',
				duration: 0.3,
				staggerChildren: 0.1,
			},
		},
	}
	const mainVariants = {
		init: {
			y: isMobile ? '100%' : '-100%',
		},
		animate: {
			y: '0%',
			transition: {
				duration: 0.7,
			},
		},
		exit: {
			y: isMobile ? '100%' : '-100%',
			transition: {
				duration: 0.4,
			},
		},
	}
	const spacerVariants = {
		init: {
			y: '-150%',
		},
		animate: {
			y: '0%',
			transition: {
				duration: 0.7,
			},
		},
		exit: {
			y: '-150%',
			transition: {
				duration: 0.4,
			},
		},
	}
	const rightBarMainVariants = {
		init: {
			x: isMobile ? '-100%' : '100%',
		},
		animate: {
			x: '0%',
			transition: {
				duration: 0.7,
			},
		},
		exit: {
			x: isMobile ? '-100%' : '100%',
			transition: {
				duration: 0.4,
			},
		},
	}
	const rightBarBottomVariants = {
		animate: {
			transition: {},
		},
		exit: {
			transition: {
				duration: 0.4,
				staggerChildren: 0.1,
			},
		},
	}
	const rightBarBottomItemsVariants = {
		init: {
			y: !isMobile ? '100%' : '0%',
			x: isMobile ? '100%' : '0%',
		},
		animate: {
			y: !isMobile ? '0%' : '0%',
			x: isMobile ? '0%' : '0%',
			transition: {
				duration: 0.7,
			},
		},
		exit: {
			y: !isMobile ? '100%' : '0%',
			x: isMobile ? '100%' : '0%',
			// onAnimationEnd: () => window.scrollTo(0, 0),
			transition: {
				duration: 0.4,
			},
		},
	}

	return (
		<Container>
			<Sound mode='home' mobile={true} />
			<Wrap>
				<LeftBar className='' variants={leftBarVariants} initial='init' animate='animate' exit='exit'></LeftBar>
				<Main variants={mainVariants} initial='init' animate='animate' exit='exit'>
					<Sound mode='home' />
					<div className='content'>
						<Overline>SPIELELAND-GEWINNSPIEL</Overline>
						<img className='mx-auto  md:ml-0 block mb-4 w-full max-w-[290px]' src={logoMemory} alt='' />
						<Description>{parse(t[locale].description)}</Description>
						<button className='bg-blue text-white md:text-lg py-2 md:py-4 px-4 rounded-xl uppercase md:tracking-wider min-w-[180px] md:min-w-[240px]' onClick={() => onStartGameClick()}>
							{t[locale].buttonStart}
						</button>
					</div>
				</Main>
				<RightBar>
					<RightBarMobileSpacer variants={spacerVariants} initial='init' animate='animate' exit='exit' />
					<motion.div className='blocks' variants={rightBarMainVariants} initial='init' animate='animate' exit='exit'>
						<img src={homeBlocks} alt='' />
					</motion.div>
					<motion.div ref={rightSquareRef} variants={rightBarBottomVariants} initial='init' animate='animate' exit='exit' className='horizontal-blocks'>
						<motion.img variants={rightBarBottomItemsVariants} src={bl01} alt='' />
						<motion.img variants={rightBarBottomItemsVariants} src={bl01} alt='' />
						<motion.img className='md:hidden 2xl:block' variants={rightBarBottomItemsVariants} src={bl01} alt='' />
						<motion.img className='hidden 2k:block' variants={rightBarBottomItemsVariants} src={bl01} alt='' />
						<motion.img className='hidden 2k:block' variants={rightBarBottomItemsVariants} src={bl01} alt='' />
					</motion.div>
				</RightBar>
			</Wrap>
		</Container>
	)
}

export default Welcome
