import React, { useState, useEffect, useCallback } from 'react'
import Card from './Card'
import tw, { styled } from 'twin.macro'
import { motion } from 'framer-motion'
import cardsData from './../data/cards.json'
import BottomGameBar from './BottomGameBar'
import { useStateContext } from '../context/stateContext'

const GameBoard = styled(motion.div)`
	${tw`absolute grid grid-cols-3 gap-2 transform -translate-y-1/2 md:grid-cols-6 mobile:gap-3 lg:gap-6 top-1/2`}
`
const ResetClickArea = styled.div`
	${tw`absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center w-full h-full overflow-hidden bg-no-repeat bg-cover bg-[#bcd88b]`}
`

const Board = () => {
	const stateContext = useStateContext()
	const { setAnimation, setEndAnimation, animationState, playSound } = useStateContext()

	const [firstCardSelected, setFirstCardSelected] = useState({ pair: 0, key: '' })
	const [secondCardSelected, setSecondCardSelected] = useState({ pair: 0, key: '' })
	const [firstCardChecked, setFirstCardChecked] = useState(false)
	const [checkingPair, setCheckingPair] = useState(false)
	const [isPair, setIsPair] = useState(false)
	const [notPair, setNotPair] = useState(null)
	const [cardOrder, setCardOrder] = useState([])
	const [foldedPairs, setFoldedPairs] = useState([])

	const [userStatus, setUserStatus] = useState({ rounds: 1, pairsCollected: 0 })

	const [cards, setCards] = useState({})
	const [deck, setDeck] = useState([])

	const cardClickHandle = (value, uniqueKey) => {
		if (stateContext.state.sound) playSound('uncover')
		if (!firstCardChecked) {
			setFirstCardSelected({ pair: value, key: uniqueKey })
			setFirstCardChecked(true)
		}
		if (firstCardChecked) {
			setSecondCardSelected({ pair: value, key: uniqueKey })
			setCheckingPair(true)
		}
		if (notPair) {
			if (stateContext.state.sound) playSound('cover')
			resetSelectedCards()
		}
	}

	const cardResetHandle = async () => {
		if (notPair) {
			setAnimation()
			if (animationState) return
			if (stateContext.state.sound) playSound('cover')
			resetSelectedCards()
			await countRounds()
			setEndAnimation()
		}
	}
	const countRounds = async () => {
		await new Promise((resolve) =>
			setTimeout(() => {
				setUserStatus((currentUserStatus) => {
					return {
						rounds: currentUserStatus.rounds + 1,
						pairsCollected: currentUserStatus.pairsCollected,
					}
				})
				return resolve()
			}, 400)
		)
	}

	const countPairs = async () => {
		await new Promise((resolve) =>
			setTimeout(() => {
				setUserStatus((currentUserStatus) => {
					return {
						rounds: currentUserStatus.rounds,
						pairsCollected: currentUserStatus.pairsCollected + 1,
					}
				})
				if (stateContext.state.sound) playSound('pairNumber')
				setFoldedPairs((currentFoldenPairs) => [...currentFoldenPairs, secondCardSelected.pair])
				resetSelectedCards()
				return resolve()
			}, 300)
		)
	}

	useEffect(() => {
		checkGameStatus()
	}, [userStatus]) // eslint-disable-line react-hooks/exhaustive-deps

	const checkGameStatus = () => {
		setTimeout(() => {
			if (userStatus.pairsCollected === 9) {
				if (stateContext.state.sound) playSound('win')
				return [
					stateContext.dispatch({ action: 'SET_GAME_STATUS', data: 'gameEnding' }),
					setTimeout(() => {
						stateContext.dispatch({ action: 'SET_GAME_STATUS', data: 'gameEnded' })
					}, 400),
					stateContext.dispatch({
						action: 'SET_FINAL_SCORE',
						rounds: userStatus.rounds,
						pairs: userStatus.pairsCollected,
					}),
				]
			}
		}, 700)
	}

	const checkForPair = useCallback(() => {
		if (firstCardSelected.pair !== 0 && secondCardSelected.pair !== 0 && firstCardSelected.pair === secondCardSelected.pair) {
			setAnimation()
			setTimeout(() => {
				setEndAnimation()
			}, 1300)
			countPairs()
			setTimeout(() => {
				if (stateContext.state.sound) playSound('pair')
			}, 500)
			setIsPair(true)
			return
		}
		if (firstCardChecked && checkingPair && !isPair) {
			setAnimation()
			setTimeout(() => {
				setEndAnimation()
			}, 700)
			setNotPair(true)
		}
	}, [cardClickHandle, isPair, notPair]) // eslint-disable-line react-hooks/exhaustive-deps

	const resetSelectedCards = () => {
		setFirstCardSelected({ pair: 0, key: '' })
		setSecondCardSelected({ pair: 0, key: '' })
		setFirstCardChecked(false)
		setCheckingPair(false)
		setIsPair(false)
		setNotPair(false)
	}

	useEffect(() => {
		if (secondCardSelected.pair !== 0 && checkingPair) {
			checkForPair()
		}
	}, [secondCardSelected, checkingPair]) // eslint-disable-line react-hooks/exhaustive-deps

	const getCards = useCallback(() => {
		function shuffle(array) {
			let currentIndex = array.length,
				randomIndex

			while (0 !== currentIndex) {
				randomIndex = Math.floor(Math.random() * currentIndex)
				currentIndex--
				;[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
			}

			return array
		}

		let pairsWithTheSameText = [...cardsData]

		const onePair = shuffle(pairsWithTheSameText)

		let shuffled = shuffle(onePair)

		setCards(cardsData)
		setDeck([...shuffled, ...shuffled])
	}, [cards, deck]) // eslint-disable-line react-hooks/exhaustive-deps
	useEffect(() => {
		document.body.classList.remove('height')

		getCards()
		setAnimation()
		setTimeout(() => {
			setEndAnimation()
		}, 1800)
		return () => document.body.classList.add('height')
	}, []) // eslint-disable-line react-hooks/exhaustive-deps
	useEffect(() => {
		if (cardOrder.length === 0) {
			getCardsOrder()
		}
	}, [cardOrder]) // eslint-disable-line react-hooks/exhaustive-deps

	const getCardsOrder = useCallback(() => {
		const order = new Set()
		while (order.size !== 18) {
			order.add(Math.floor(Math.random() * 19) + 1)
		}
		const toArray = Array.from(order)
		return setCardOrder(toArray)
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const cardsVariants = {
		animate: {
			transition: {
				staggerChildren: 0.09,
			},
		},
	}
	const cardsSingleVariants = {
		init: {
			scale: 1.5,
			opacity: 0,
			transformOrigin: '50% 50%',
		},
		animate: {
			scale: 1,
			opacity: 1,
			transition: {
				type: 'spring',
				duration: 0.4,
			},
		},
		exit: {
			scale: 0,
			opacity: 0,
			transition: {
				duration: 0.5,
			},
		},
	}

	return (
		<>
			<ResetClickArea onClick={cardResetHandle}>
				<GameBoard key='gameboard' variants={cardsVariants} initial='init' animate={Object.keys(deck).length > 0 ? 'animate' : 'init'} exit='exit'>
					{Object.keys(deck).length > 0 &&
						Object.values(deck).map(({ key, lang, pair, headlines }, index) => {
							return (
								<motion.div
									style={{
										order: cardOrder[index],
									}}
									onAnimationStart={() => {
										if (stateContext.state.sound && index % 2 === 0) playSound(`spawn_00${parseInt(Math.random() * 3 + 1)}`, true)
									}}
									key={`${key}_${index % 2 === 0 ? '1' : '2'}`}
									variants={cardsSingleVariants}
								>
									<Card
										uniqueKey={`${key}_${index % 2 === 0 ? '1' : '2'}`}
										firstCardSelected={firstCardSelected}
										secondCardSelected={secondCardSelected}
										isPair={isPair}
										notPair={notPair}
										index={pair}
										lang={lang}
										flipDeckSrc={require('../assets/spieleland/' + cardsData[pair - 1].frontUrl).default}
										headlines={headlines}
										cardClickHandle={cardClickHandle}
										foldedPairs={foldedPairs}
									/>
								</motion.div>
							)
						})}
				</GameBoard>
			</ResetClickArea>
			<BottomGameBar key='bottombar' userStatus={userStatus} />
		</>
	)
}

export default Board
